import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import css from 'styled-jsx/css';

import Column from '../Column';
import { FormInput } from '../Input';
import WhenFieldChanges from '../WhenFieldChanges';
import { FormCheckbox } from '../CheckBox';
import { brownGrey, greyishBrown } from '../../css-utilities';
import { ErrorTypes } from '../../lib/validation';

const styledCheckbox = css.resolve`
  width: auto !important;
  white-space: nowrap;
`;

const CourseAttendeeForm = ({ attendeeNumber, setIsChecked, values }) => {
  const {
    FIELD_IS_REQUIRED,
    EMAIL_IS_NOT_VALID,
    PHONE_IS_NOT_VALID,
  } = ErrorTypes;

  const updateSetIsChecked = useCallback(e => {
    setIsChecked(e.target.value);
  }, []);

  return (
    <>
      <div className="header">
        <h3>{`Deltagare ${attendeeNumber}`}</h3>
        {attendeeNumber === 1 && (
          <FormCheckbox
            className={styledCheckbox.className}
            name="same_as"
            onChange={updateSetIsChecked}
          >
            <span>Samma som beställare</span>
          </FormCheckbox>
        )}
      </div>
      <div className="flex">
        <Column width="50">
          <div style={{ marginRight: '2%' }}>
            {attendeeNumber === 1 && (
              <WhenFieldChanges
                becomes
                field="same_as"
                set={`attendee ${attendeeNumber} firstname`}
                to={values['buyer firstname']}
              />
            )}
            <FormInput
              errorMessages={{
                [FIELD_IS_REQUIRED]: 'Vänligen ange ett förnamn',
              }}
              label="Förnamn*"
              name={`attendee ${attendeeNumber} firstname`}
            />
          </div>
        </Column>
        <Column width="50">
          <div style={{ marginLeft: '2%' }}>
            {attendeeNumber === 1 && (
              <WhenFieldChanges
                becomes
                field="same_as"
                set={`attendee ${attendeeNumber} lastname`}
                to={values['buyer lastname']}
              />
            )}
            <FormInput
              errorMessages={{
                [FIELD_IS_REQUIRED]: 'Vänligen ange ett efternamn',
              }}
              label="Efternamn*"
              name={`attendee ${attendeeNumber} lastname`}
            />
          </div>
        </Column>
      </div>
      {attendeeNumber === 1 && (
        <WhenFieldChanges
          becomes
          field="same_as"
          set={`attendee ${attendeeNumber} email`}
          to={values['buyer email']}
        />
      )}
      <FormInput
        errorMessages={{
          [FIELD_IS_REQUIRED]: 'Vänligen ange en e-postadress',
          [EMAIL_IS_NOT_VALID]: 'Vänligen ange en giltig e-postadress',
        }}
        label="E-post*"
        name={`attendee ${attendeeNumber} email`}
        type="email"
      />
      {attendeeNumber === 1 && (
        <WhenFieldChanges
          becomes
          field="same_as"
          set={`attendee ${attendeeNumber} phone`}
          to={values['buyer phone']}
        />
      )}
      <FormInput
        errorMessages={{
          [PHONE_IS_NOT_VALID]: 'Vänligen ange ett giltigt telefonnummer.',
        }}
        label="Telefon"
        name={`attendee ${attendeeNumber} phone`}
      />

      {styledCheckbox.styles}

      <style jsx>
        {`
          .header {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin: 28px 0;
          }

          .header h3 {
            margin: 0;
          }

          .header span {
            font-family: Lato;
            font-size: 16px;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: ${greyishBrown};
          }

          input {
            -webkit-appearance: none;
            width: 100%;
            font-family: 'Lato';
            font-size: 14px;
            color: ${brownGrey};
            padding: 14px 30px;
            border-radius: 20px;
            border: none;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
            -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
            margin: 10px 0;
          }
        `}
      </style>
    </>
  );
};

CourseAttendeeForm.defaultProps = {
  attendeeNumber: 1,
  setIsChecked: false,
  values: {},
};

CourseAttendeeForm.propTypes = {
  attendeeNumber: PropTypes.number,
  setIsChecked: PropTypes.func,
  values: PropTypes.object,
};

export default CourseAttendeeForm;
