import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import { veryLightPink } from '../../css-utilities';
import { composeValidators, pickValidator } from '../../lib/validation';

const CheckBox = ({
  border,
  background,
  onChange,
  className,
  errorMessages,
  name,
  children,
  errors,
  invalid,
  touched,
}) => {
  return (
    <label className={`checkbox ${className}`} htmlFor={`checkbox_${name}`}>
      <div className="checkbox-label">
        <div className="checkbox-check-wrapper">
          <input id={`checkbox_${name}`} onChange={onChange} type="checkbox" />
          <i className="fas fa-times checkbox-mark" />
        </div>
      </div>

      <div className="checkbox-content">
        {invalid && touched && (
          <span className="error-message">{errorMessages[errors[0]]}</span>
        )}
        {children}
      </div>

      <style jsx>
        {`
          .checkbox {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
          }
          .checkbox-content {
            width: 100%;
            padding-left: 15px;
          }
          .error-message {
            display: block;
            width: 100%;
            margin-bottom: 2px;
            color: red;
          }
          .checkbox-label {
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border: ${border ? `1px solid ${veryLightPink};` : ''};
            background-color: ${background};
            display: inline-block;
            position: relative;
            cursor: pointer;
          }

          .checkbox-label label {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: calc(50% - 25px / 2);
            cursor: pointer;
            background: ${invalid && touched ? '#ffc1c1' : 'transparent'};
            border: 1px solid ${invalid && touched ? 'crimson' : 'transparent'};
            border-radius: 5px;
            box-shadow: 0 0 5px
              ${invalid && touched ? '#ffc1c1' : 'transparent'};
          }

          .checkbox-mark {
            font-size: 25px;
            position: absolute;
            top: -1px;
            left: -1px;
            width: 25px;
            height: 25px;
            border-radius: 5px;
            border: 1px solid
              ${invalid && touched ? veryLightPink : veryLightPink};
          }
          .checkbox-mark:before {
            position: relative;
            opacity: 0;
          }

          .checkbox-check-wrapper input[type='checkbox'] {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
          }

          .checkbox-check-wrapper input[type='checkbox']:invalid {
            box-shadow: none;
            border: none;
          }

          .checkbox-check-wrapper input[type='checkbox']::before {
            content: '';
            display: block;
            position: absolute;
            background: ${background};
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          .checkbox-check-wrapper
            input[type='checkbox']:checked
            ~ .checkbox-mark:before {
            opacity: 1;
          }
          .checkbox-check-wrapper input[type='checkbox']:focus ~ .checkbox-mark,
          .checkbox-check-wrapper
            input[type='checkbox']:hover
            ~ .checkbox-mark {
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4);
          }
        `}
      </style>
    </label>
  );
};

CheckBox.defaultProps = {
  background: '#fff',
  border: false,
  className: '',
  errorMessages: {},
  errors: [],
  invalid: false,
  onChange: null,
  required: false,
  touched: true,
};

CheckBox.propTypes = {
  background: PropTypes.string,
  border: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  className: PropTypes.string,
  errorMessages: PropTypes.object,
  errors: PropTypes.array,
  invalid: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  touched: PropTypes.bool,
};

export default CheckBox;

export const FormCheckbox = ({ errorMessages, name, ...props }) => {
  let validate = null;
  if (errorMessages) {
    let validators = Object.keys(errorMessages).map(message =>
      pickValidator(message)
    );
    validate = composeValidators(validators);
  }

  return (
    <Field name={name} type="checkbox" validate={validate}>
      {({ input, meta }) => (
        <CheckBox
          errorMessages={errorMessages}
          errors={meta.error}
          name={name}
          {...props}
          {...meta}
          {...input}
        />
      )}
    </Field>
  );
};

FormCheckbox.defaultProps = {
  errorMessages: {},
};

FormCheckbox.propTypes = {
  errorMessages: PropTypes.object,
  name: PropTypes.string.isRequired,
};
