import React from 'react';

import Column from '../Column';
import { brownGrey } from '../../css-utilities';
import { FormInput } from '../Input';
import { ErrorTypes } from '../../lib/validation';

const BuyerForm = () => {
  const {
    FIELD_IS_REQUIRED,
    EMAIL_IS_NOT_VALID,
    SHOULD_BE_NUMBER,
    PHONE_IS_NOT_VALID,
    ORG_NUMBER_IS_NOT_VALID,
  } = ErrorTypes;
  return (
    <>
      <h3>Beställare</h3>
      <div className="flex">
        <Column width="50">
          <div style={{ marginRight: '2%' }}>
            <FormInput
              errorMessages={{
                [FIELD_IS_REQUIRED]: 'Vänligen ange ett förnamn',
              }}
              label="Förnamn*"
              name="buyer firstname"
            />
          </div>
        </Column>
        <Column width="50">
          <div style={{ marginLeft: '2%' }}>
            <FormInput
              errorMessages={{
                [FIELD_IS_REQUIRED]: 'Vänligen ange ett efternamn',
              }}
              label="Efternamn*"
              name="buyer lastname"
            />
          </div>
        </Column>
      </div>

      <FormInput
        errorMessages={{
          [FIELD_IS_REQUIRED]: 'Vänligen ange en e-postaddress',
          [EMAIL_IS_NOT_VALID]: 'Vänligen ange en giltig e-postaddress',
        }}
        label="E-post*"
        name="buyer email"
        type="email"
      />

      <div className="flex">
        <Column width="50">
          <div style={{ marginRight: '2%' }}>
            <FormInput
              errorMessages={{
                [FIELD_IS_REQUIRED]: 'Vänligen ange ett företagsnamn',
              }}
              label="Företag*"
              name="company"
            />
          </div>
        </Column>
        <Column width="50">
          <div style={{ marginLeft: '2%' }}>
            <FormInput
              errorMessages={{
                [FIELD_IS_REQUIRED]: 'Vänligen ange ett org. nummer',
                [ORG_NUMBER_IS_NOT_VALID]: 'Vänligen ange giltigt org. nummer',
              }}
              label="Org. nr (XXXXXX-XXXX)*"
              name="orgnr"
            />
          </div>
        </Column>
      </div>

      <FormInput
        errorMessages={{
          [FIELD_IS_REQUIRED]: 'Vänligen ange en adress',
        }}
        label="Adress*"
        name="buyer primary address"
      />

      <FormInput label="Alternativ adress" name="alternative address" />

      <div className="flex">
        <Column width="50">
          <div style={{ marginRight: '2%' }}>
            <FormInput
              errorMessages={{
                [FIELD_IS_REQUIRED]: 'Vänligen ange ett postnummer',
                [SHOULD_BE_NUMBER]: 'Vänligen ange giltigt postnummer',
              }}
              label="Postnummer*"
              name="zip"
            />
          </div>
        </Column>

        <Column width="50">
          <div style={{ marginLeft: '2%' }}>
            <FormInput
              errorMessages={{
                [FIELD_IS_REQUIRED]: 'Vänligen ange en postort',
              }}
              label="Ort*"
              name="municipality"
            />
          </div>
        </Column>
      </div>

      <FormInput
        errorMessages={{
          [FIELD_IS_REQUIRED]: 'Vänligen ange ett telefonnummer',
          [PHONE_IS_NOT_VALID]: 'Vänligen ange ett giltigt telefonnummer.',
        }}
        label="Telefon"
        name="buyer phone"
        type="tel"
      />

      <style jsx>
        {`
          input {
            -webkit-appearance: none;
            width: 100%;
            font-family: 'Lato';
            font-size: 14px;
            color: ${brownGrey};
            padding: 14px 30px;
            border-radius: 20px;
            border: none;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
            margin: 10px 0;
          }
        `}
      </style>
    </>
  );
};

export default BuyerForm;
