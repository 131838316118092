import { useState, useEffect } from 'react';
import { createAttendee } from '../../utils';

export default () => {
  const initialNumberOfAttendees = 1;
  // number of attendees
  const [numberOfAttendees, setNumberOfAttendees] = useState(
    initialNumberOfAttendees
  );
  const handleSetNumberOfAttendees = value => {
    setNumberOfAttendees(parseInt(value));
  };

  // attendees
  const [attendees, setAttendees] = useState([]);
  const handleSetAttendeeFieldValue = (id, key, value) => {
    setAttendees(attendees => {
      return attendees.map(attendee =>
        attendee.id === id ? { ...attendee, [key]: value } : attendee
      );
    });
  };

  // mount
  useEffect(() => {
    setAttendees(
      Array.from({ length: numberOfAttendees }, (_, id) => createAttendee(id))
    );
  }, []);

  // on update numberOfAttendees
  useEffect(() => {
    if (attendees.length) {
      const isIncrement = attendees.length < numberOfAttendees;

      // increment
      if (isIncrement) {
        let lastId = attendees[attendees.length - 1].id;
        const newAttendees = Array.from(
          { length: numberOfAttendees - attendees.length },
          () => createAttendee(++lastId)
        );

        const oldAttendees = attendees.map(a => ({ ...a, active: true }));
        setAttendees([...oldAttendees, ...newAttendees]);
      }

      // decrement
      if (!isIncrement) {
        setAttendees(
          attendees.map(attendee => ({
            ...attendee,
            active: attendee.id < numberOfAttendees,
          }))
        );
      }
    }
  }, [numberOfAttendees]);

  return {
    numberOfAttendees,
    handleSetNumberOfAttendees,
    attendees,
    handleSetAttendeeFieldValue,
  };
};
