import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  veryLightBluishGray,
  veryLightPink,
  greyishBrown,
} from '../../css-utilities';

const StyledSelect = ({ currentValue, length, onChange }) => {
  const onSelect = useCallback(e => {
    onChange(e.target.value);
  });

  return (
    <div className="select__wrapper">
      <select onChange={onSelect} value={currentValue}>
        {Array.from({ length }, (_, value) => (
          <option key={value} value={value + 1}>
            {value + 1}
          </option>
        ))}
      </select>
      <span className="value">{currentValue}</span>
      <i className="fas fa-chevron-down arrow" />
      <style jsx>
        {`
          select {
            opacity: 0;
            border: none;
            font-size: 20px;
            width: 100%;
            height: 100%;
            z-index: 1;
            background-color: transparent;
            color: white;
          }

          .select__wrapper {
            width: 145px;
            height: 60px;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 30px;
            position: relative;
            border: 1px solid ${veryLightPink};
          }

          .value {
            position: absolute;
            left: 25%;
            font-family: 'Lato';
          }

          .arrow {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 50%;
            top: 0;
            right: 40px;
            right: 0;
            background-color: ${veryLightBluishGray};
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            color: ${greyishBrown};
          }
        `}
      </style>
    </div>
  );
};

StyledSelect.propTypes = {
  currentValue: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default StyledSelect;
