import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Form } from 'react-final-form';

import Document from '../components/Document';
import Section from '../components/Section';
import Column from '../components/Column';
import CourseAttendeeForm from '../components/CourseAttendeeForm';
import BuyerForm from '../components/BuyerForm';
import OrderSummaryInterestedPage from '../components/OrderSummaryInterestedPage';
import StyledSelect from '../components/StyledSelect';
import { FormInput } from '../components/Input';

import useAttendeesState from '../lib/hooks/useAttendeesState/index';

import {
  brownGrey,
  bluishPurple,
  veryLightBluishGray,
  breakpoints,
  darkishPink,
} from '../css-utilities';

const InterestedPage = ({ data: { course, interested } }) => {
  const orderForm = useRef();
  const numberOfAttendeesState = 30;

  const submitForm = useCallback(() => {
    // submit form
    orderForm.current.submit();
  });

  const {
    handleSetNumberOfAttendees,
    numberOfAttendees,
    attendees,
  } = useAttendeesState(numberOfAttendeesState);

  const [isChecked, setIsChecked] = useState(false);

  return (
    <Document seo={course.seo}>
      <div className="booking__wrapper">
        <Form onSubmit={submitForm}>
          {({ handleSubmit, values }) => {
            const renderAttendeeFields = attendees.map(
              (attendee, index) =>
                attendee.active && (
                  <Column key={attendee.id} width="100">
                    <CourseAttendeeForm
                      attendeeNumber={index + 1}
                      isChecked={isChecked}
                      setIsChecked={setIsChecked}
                      values={values}
                    />
                  </Column>
                )
            );

            return (
              <form
                action={`https://formcarry.com/s/${
                  process.env.GATSBY_FORMCARRY_BOOKING_ID
                }`}
                method="POST"
                onSubmit={handleSubmit}
                ref={orderForm}
              >
                <div className="flex wrap attendees-form">
                  <section className="attendees-form-section-wrapper">
                    <Section noMargin noPadding>
                      <Column width="100">
                        <h4 className="course-name">{course.name}</h4>
                      </Column>

                      <Column width="100">
                        <input
                          name="course"
                          type="hidden"
                          value={course.name}
                        />
                        <BuyerForm />
                      </Column>
                    </Section>

                    <Section noMargin noPadding>
                      <Column width="100">
                        <h3>Antal deltagare</h3>
                      </Column>
                      <div className="select">
                        <StyledSelect
                          currentValue={numberOfAttendees}
                          length={numberOfAttendeesState}
                          onChange={handleSetNumberOfAttendees}
                        />
                      </div>

                      {renderAttendeeFields}
                    </Section>

                    <Section noMargin noPadding>
                      <h3>Övrigt</h3>
                      <FormInput label="Meddelande..." name="other" textarea />
                    </Section>
                  </section>

                  <aside>
                    <OrderSummaryInterestedPage
                      attendees={attendees}
                      course={course}
                      courseLeader={interested.courseLeader}
                      coursePrice={course.price}
                      date={interested.date}
                      numberOfAttendees={numberOfAttendees}
                    />
                  </aside>
                </div>
              </form>
            );
          }}
        </Form>
      </div>

      <style jsx>
        {`
          .booking__wrapper {
            grid-column: 1 / -1;
          }
          .course-name {
            color: ${bluishPurple};
          }
          .attendees-form {
            margin-left: none;
            position: relative;
          }

          .attendees-form-section-wrapper {
            width: 100%;
            margin: 50px 0;
            padding: 0 20px;
          }

          aside {
            position: relative;
            width: 100%;
            background: ${veryLightBluishGray};
          }

          .select {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
          }

          .notice {
            width: 265px;
            height: 18px;
            margin-left: 35px;
            font-family: Lato;
            font-size: 15px;
            font-weight: 900;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: ${darkishPink};
          }

          @media (min-width: ${breakpoints.desktopSM}) {
            .attendees-form-section-wrapper {
              width: 50%;
              margin: 100px 0;
            }
          }

          @media (min-width: ${breakpoints.tablet}) {
            .attendees-form-section-wrapper {
              width: 60%;
            }

            aside {
              width: 40%;
            }
          }

          @media (min-width: ${breakpoints.desktopMD}) {
            .attendees-form-section-wrapper {
              width: 70%;
              padding-left: 100px;
              padding-right: 100px;
            }
            aside {
              width: 30%;
              margin-top: 0;
            }
          }

          textarea {
            width: 100%;
            height: 250px;
            max-width: 100%;
            min-width: 100%;
            font-family: 'Lato';
            font-size: 14px;
            color: ${brownGrey};
            padding: 14px 30px;
            border-radius: 20px;
            border: none;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
          }
        `}
      </style>
    </Document>
  );
};

InterestedPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query interestedPageQuery($courseId: String!) {
    course: datoCmsCourse(id: { eq: $courseId }) {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      name
    }
    interested: datoCmsInterested {
      date
      courseLeader
    }
  }
`;

InterestedPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default InterestedPage;
