import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import OrderSummary from './OrderSummaryInterestedPage';

const MEDIA_MEDIUM_BREAKPOINT = 768;

const OrderSummaryWrapper = ({ course, attendees, ...props }) => {
  const [orderSummaryClassName, setOrderSummaryClassName] = useState('');

  const orderSummaryScrollSpy = () => {
    // look up the navigation element
    const HEADER_HEIGHT = document
      .getElementById('navigation')
      .getBoundingClientRect().height;

    const { scrollY, innerWidth } = window;
    const hasScrolledBelowHeader = scrollY >= HEADER_HEIGHT;

    setOrderSummaryClassName(
      hasScrolledBelowHeader && innerWidth > MEDIA_MEDIUM_BREAKPOINT
        ? 'sticky'
        : ''
    );
  };

  useEffect(() => {
    window.addEventListener('scroll', orderSummaryScrollSpy);

    // clean up
    return () => window.removeEventListener('scroll', orderSummaryScrollSpy);
  }, []);

  return (
    <OrderSummary
      attendees={attendees}
      course={course}
      orderSummaryClassName={orderSummaryClassName}
      {...props}
    />
  );
};

OrderSummaryWrapper.propTypes = {
  attendees: PropTypes.array.isRequired,
  course: PropTypes.object.isRequired,
  numberOfAttendees: PropTypes.number.isRequired,
};

export default OrderSummaryWrapper;
